@import 'src/styles/font-stack';
@import 'src/styles/media';

$label-top-large: 1rem;
$label-top-medium: 0.75rem;

.container {
  position: relative;
  margin-bottom: 2rem;

  @include media(md) {
    margin-bottom: 2rem;
  }
  &.small {
    margin-bottom: 0.75rem;
    @include media(md) {
      margin-bottom: 0.75rem;
    }
  }
  select {
    @include font-input-value-24;
    color: var(--color-dark-blue);
    appearance: none;
    width: 100%;
    background-color: var(--color-white);
    font-family: var(--font-family);
    border: {
      style: solid;
      width: 0.125rem;
      color: var(--color-dark-blue-100);
    }
    transition: border 300ms ease-out;
    padding: 1.75rem 1.5rem 0.25rem;
    border-radius: 1rem;
    background: var(--color-white) url('/assets/icons/default/chevron-down.svg') no-repeat top
      0.8906rem right 0.8906rem / 1rem 1rem;

    cursor: pointer;

    &[type='number'] {
      -moz-appearance: textfield;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    &::placeholder {
      opacity: 0;
      transition: opacity 300ms ease-out;
      color: var(--color-dark-blue-100);
    }

    &:hover {
      border-color: var(--color-dark-blue-300);
    }

    &:focus {
      outline: none;
      border-color: var(--color-text-primary);

      &::placeholder {
        opacity: 100;
      }

      + label {
        @include font-overline-10;
        top: $label-top-large;
      }
    }

    &:disabled {
      background-color: var(--color-stone-grey-900);
      border-color: var(--color-stone-grey-900);
      color: var(--color-dark-blue-100);

      &::placeholder {
        color: var(--color-dark-blue-100);
      }
    }
  }

  .error {
    border-color: var(--color-error);

    &:hover,
    &:focus {
      border-color: var(--color-error);
    }
  }

  label {
    @include font-input-value-24;
    color: var(--color-dark-blue-500);
    display: inline;
    position: absolute;
    top: 1.375rem;
    left: 1.625rem;
    pointer-events: none;
    transition: all 300ms ease-out;

    &.value {
      @include font-overline-10;
      top: $label-top-large;
    }
  }

  .errorMessage {
    @include font-regular-14;
    color: var(--color-error);
    line-height: 1.375rem;
    padding-left: 1.5rem;
  }
}

.medium {
  select {
    @include font-input-value-20;
    padding: 1.5rem 1.25rem 0.25rem;
    border-radius: 0.75rem;

    &:focus {
      + label {
        top: $label-top-medium;
      }
    }
  }

  label {
    @include font-input-value-20;
    top: 1.25rem;
    left: 1.375rem;

    &.value {
      top: $label-top-medium;
    }
  }

  .errorMessage {
    padding-left: 1.25rem;
  }
}

.small {
  select {
    @include font-input-value-20;
    color: var(--color-dark-blue-500);
    padding: 1.2rem 1.25rem 1rem 1rem;
    border-radius: 0.75rem;
    background: var(--color-white) url('/assets/icons/default/chevron-down.svg') no-repeat top
      1.3rem right 0.8906rem / 1rem 1rem;
    &:focus {
      + label {
        top: $label-top-medium;
      }
    }
  }

  label {
    @include font-input-value-20;
    top: 1.25rem;
    left: 1.375rem;

    &.value {
      top: $label-top-medium;
    }
  }

  .errorMessage {
    padding-left: 1.25rem;
  }
}

.inline {
  select {
    background-color: transparent;
    border-color: transparent;

    &:hover,
    &:focus,
    &:disabled,
    &.error,
    &.error:hover,
    &.error:focus {
      border-color: transparent;
    }

    &:hover:not(:disabled) {
      + label {
        color: var(--color-text-primary);
      }
    }

    &::placeholder {
      color: var(--color-dark-blue-500);
    }

    &.error {
      color: var(--color-error);
    }

    &:disabled {
      background-color: transparent;
      color: var(--color-dark-blue-100);

      &::placeholder {
        color: var(--color-dark-blue-100);
      }
    }

    + label.value {
      color: var(--color-dark-blue-500);
    }
  }
  .errorMessage {
    color: var(--color-dark-blue-500);
  }
}
