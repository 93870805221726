$normalMargin: 0.75rem;
$smallMargin: 0.25rem;
$largeMargin: 1rem;

.list {
  list-style: none;
  padding: 1rem 0.5rem;
  margin: 0;

  hr {
    &:last-child {
      display: none;
    }
  }
}

@mixin dividerAndListItem($margin) {
  hr {
    margin-top: $margin;
    margin-bottom: $margin;
  }

  &.showMoreButton {
    li {
      &:last-of-type {
        margin-bottom: $margin;
      }
    }
  }
}

.normal {
  @include dividerAndListItem($normalMargin);
}

.small {
  @include dividerAndListItem($smallMargin);
}

.large {
  @include dividerAndListItem($largeMargin);
}

.showMoreButton {
  hr {
    &:last-of-type {
      display: none;
    }
  }
}
