@import 'src/styles/font-stack';

.button {
  cursor: pointer;
  padding: 0;
  border: 0;
  background-color: transparent;
}

.modal {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 2rem);
  max-width: 30rem;
  border-radius: 1rem;
  padding: 2.5rem 2.5rem;
  background-color: var(--color-stone-grey-300);
  color: var(--color-dark-blue);
  z-index: var(--layer-andromeda);
  box-shadow: var(--elevation-high);

  h4 {
    @include font-title-32;
    margin-bottom: 0.875rem;
  }

  p {
    @include font-regular-18;
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  svg {
    margin-top: 1rem;
    max-width: 13rem;
  }
}

.close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  border: none;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  appearance: none;

  svg {
    margin-top: 0;
    max-width: unset;
  }
}
