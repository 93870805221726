@import 'src/styles/font-stack';

.extra-controls {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .control {
    display: grid;
    grid-template-columns: auto 1fr auto;
    justify-content: space-between;
    align-items: center;
    height: 3.75rem;
    gap: 1rem;

    // @todo Handle in InputSwitch component. Then remove this.
    [class*='inverted'] & {
      [class*='switch'] {
        input:checked + label {
          background: var(--color-mint-sweet-500);
        }
        label {
          background: var(--color-dark-blue-300);
        }
      }
    }

    .label {
      @include font-input-label-16;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 0.3125rem;

      .inline-icon {
        height: 1.625rem;
        width: auto;

        path {
          fill: var(--color-text-calculator);
        }
      }
    }
  }
}
.link {
  text-decoration: underline;
}
