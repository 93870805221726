.loan-summary {
  // @todo Replace much this stylesheet with a proper List Specification component.

  ul {
    padding: 0;
  }

  hr {
    border-top: 0;
    margin: 0.5rem 0 0;
  }
}
