// @import "/styles/variables.scss";
@import '../mixins.scss';

.afixWrapper {
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
  border: 1px solid #d8d8d8;
  border-radius: 13px;
  padding: 4px 12px;
  background: white;
  transition-duration: 0.25s;

  &:hover {
    border: 1px solid green;
  }
}

.stateless:hover {
  border: 1px solid #d2d2d2;
}

.wrappedInput {
  @include fontSize('md');

  height: 100%;
  min-width: 0;
  border: none;
  padding: 0 8px;
  flex: 1;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: #b4b4b4;
  }
}

.focused {
  border: 1px solid green;
  box-shadow: 0 0 1px 1px green;

  &.inputError {
    border: 1px solid red;
    box-shadow: 0 0 1px 1px red;
  }

  &.stateless {
    border: 1px solid #d8d8d8;
    box-shadow: none;
  }
}

.simpleInput {
  @include fontSize('md');

  height: 50px;
  width: 100%;
  min-width: 0;
  border: 1px solid #d8d8d8;
  border-radius: 13px;
  color: black;
  padding: 4px 12px;
  transition-duration: 0.25s;
  background-color: white;
  margin-bottom: 1rem;

  &::placeholder {
    color: #b4b4b4;
  }

  &:hover {
    border: 1px solid green;

    &.inputError {
      border: 1px solid red;
    }
  }

  &:focus {
    border: 1px solid #19e4ac;
    box-shadow: 0 0 1px 1px #19e4ac;

    &.inputError {
      border: 1px solid red;
      box-shadow: 0 0 1px 1px red;
    }
  }

  &:focus-visible {
    outline: none;
  }
}

.inputError {
  border: 1px solid var(--color-error);

  &:hover {
    border: 1px solid var(--color-error);
  }
}

.error {
  padding-left: 4px;
  margin-bottom: 1rem;
  margin-top: -10px;
  color: var(--color-error);
  text-align: left;
}

.noStyle {
  border: none !important;
  box-shadow: none !important;
  background: transparent;

  ::placeholder {
    display: none;
  }
}
