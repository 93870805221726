@import 'src/styles/font-stack';

.checkbox {
  display: inline-flex;
  align-items: center;
  column-gap: 0.75rem;
  position: relative;
  cursor: pointer;

  input {
    height: 2rem;
    width: 2rem;
    min-width: 2rem;
    margin: 0;
    appearance: none;
    border: 0.125rem solid var(--color-dark-blue-900);
    border-radius: 0.25rem;
    outline: none;
    background-color: transparent;
    cursor: pointer;

    &.error {
      border-color: var(--color-error);
    }

    &:checked {
      border: none;
      background: {
        color: var(--color-mint-sweet-500);
        image: url('data:image/svg+xml,%3Csvg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 -1 24 26"%3E%3Cpath d="M23.25.749 8.158 22.308a2.2 2.2 0 0 1-3.569.059L.75 17.249" stroke="%23000033" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/svg%3E');
        repeat: no-repeat;
        size: 1rem 1rem;
        position: center;
      }

      &:disabled {
        background-image: url('data:image/svg+xml,%3Csvg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 -1 24 26"%3E%3Cpath d="M23.25.749 8.158 22.308a2.2 2.2 0 0 1-3.569.059L.75 17.249" stroke="%23b7b7c1" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/svg%3E');
      }
    }

    &:indeterminate {
      border: none;
      background: {
        color: var(--color-mint-sweet-500);
        image: url('data:image/svg+xml,%3Csvg viewBox="-1 0 24 2" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M21 1H1" stroke="%23000033" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/svg%3E');
        repeat: no-repeat;
        size: 1.062rem 1.062rem;
        position: center;
      }

      &:disabled {
        background-image: url('data:image/svg+xml,%3Csvg viewBox="-1 0 24 2" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M21 1H1" stroke="%23b7b7c1" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/svg%3E');
      }
    }

    &:disabled {
      cursor: default;
      border-color: var(--color-dark-blue-100);

      &:checked,
      &:indeterminate {
        background-color: var(--color-mint-sweet-100);
      }

      + span {
        cursor: default;
        color: var(--color-dark-blue-100);
      }
    }
  }

  span {
    color: var(--color-dark-blue-900);

    &.large {
      @include font-input-label-20;

      a {
        @include font-link-regular-20;
      }
    }

    &.small {
      @include font-input-label-14;

      a {
        @include font-link-semibold-14;
      }
    }
  }

  &:hover {
    input:not(:checked):not(:indeterminate):not(:disabled) {
      border-color: var(--color-dark-blue-700);
      background-color: var(--color-stone-grey-100);
    }

    span {
      color: var(--color-dark-blue-700);
    }
  }
}

.disabled {
  cursor: default;
}

.errorMessage {
  @include font-regular-14;
  color: var(--color-error);
  line-height: 1.375rem;
  padding-left: 1.5rem;
}
