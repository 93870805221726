@import 'src/styles/font-stack';

.loan-slider {
  .slider {
    display: grid;
    justify-content: space-between;
    grid-template-columns: auto auto;

    .text-right {
      text-align: right;
    }
  }
}
