// @import "/styles/variables.scss";
@import '../mixins.scss';

.inputWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
  align-items: center;
  margin-bottom: 12px;
}

.label {
  font-size: 17px;

  color: black;
  font-weight: normal;

  @media (min-width: 768px) {
    font-size: 18px;
    font-weight: bold;
  }
}

.large {
  max-width: 130px;

  @media (min-width: 768px) {
    max-width: 170px;
  }
}

.small {
  max-width: 100px;

  @media (min-width: 768px) {
    max-width: 120px;
  }
}

.input {
  justify-self: flex-end;
  height: 36px;
  border: none;
  padding: 0;
  box-shadow: none;

  &:hover {
    border: none;
  }

  input {
    @include fontSize('sm');

    min-width: 0;
    border: 1px solid #eaeaea;
    border-right: 0;
    border-radius: 4px 0 0 4px;
    padding: 0;
    text-align: center;
    box-shadow: inset 0 0 2px rgb(0 0 0 / 30%);
    font-weight: 700;
    background: white;
    color: #000033;

    @media (min-width: 768px) {
      @include fontSize('lg');
    }
  }

  span {
    @include fontSize('sm');

    @media (min-width: 768px) {
      @include fontSize('lg');
    }
  }
}

.smallInput {
  @media (max-width: 768px) {
    input {
      font-size: 18px;
    }
  }
}

.suffix {
  @include fontSize('lg');

  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  height: 100%;
  border: 1px solid #eaeaea;
  border-radius: 0 4px 4px 0;
  padding: 0 8px;
  font-weight: 700;
  color: #000;
  background: white;
}

.inputGroup {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
}

.inputSymbol {
  all: unset;
  cursor: pointer;
  font-size: 25px;
  font-weight: 600;
  color: black;
  background: #00abe9;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  height: 36px;
  width: 36px;
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.17406));
}

.inverted {
  .label {
    color: white;
  }
  .suffix {
    box-shadow: none;
  }
}
