@use 'sass:math';

@mixin border3d($size: 'medium', $radius: 5px, $color: black) {
  $borderSize: 2px;
  $shadowColor: black;

  @if $color == $blue {
    $shadowColor: $blue;
  }

  @if $size == 'small' {
    $borderSize: 1px;

    margin: 0 5px 3px 0;
    box-shadow: 5px 4px 0 -1px $shadowColor, 5px 4px 0 0.2px $color;
  } @else {
    margin: 0 8px 7px 0;
    box-shadow: 8px 7px 0 -1px $shadowColor, 8px 7px 0 1px $color;
  }

  position: relative;
  border: $borderSize solid $color;
  border-radius: $radius;
  background-color: white;
}

@mixin fontSize($size: 'md') {
  @if ($size== 'xs') {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.6px;
  } @else if($size == 'sm') {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.8px;
  } @else if($size == 'md') {
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.9px;
  } @else if($size == 'lg') {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 1.1px;
  } @else if($size == 'xl') {
    font-size: 30px;
    line-height: 40px;
    letter-spacing: 1.5px;
  } @else if($size == '2xl') {
    font-size: 40px;
    line-height: 50px;
    letter-spacing: 2.25px;
  } @else if($size == '3xl') {
    font-size: 50px;
    line-height: 60px;
    letter-spacing: 2.5px;
  } @else {
    font-size: 1rem;
    line-height: 1.438rem;
    letter-spacing: 0.8px;
  }
}

@mixin strapiGrid {
  @for $i from 0 through 24 {
    .phone-#{$i} {
      @media (max-width: 1024px) {
        flex: 0 0 (math.div($i, 24) * 100%);
        max-width: (math.div($i, 24) * 100%);
        padding: 12px 0;
      }
    }

    .desktop-#{$i} {
      @media (min-width: 1024px) {
        flex: 0 0 (math.div($i, 24) * 100%);
        max-width: (math.div($i, 24) * 100%);
        padding: 12px;
      }
    }
  }
}

@mixin sectionTitle() {
  @include fontSize('lg');

  color: black;
  font-weight: 700;
  text-align: start;

  @media (min-width: 765px) {
    @include fontSize('3xl');
  }
}

@mixin display-flex() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin display-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@mixin align-items($type) {
  -webkit-box-align: $type;
  -ms-flex-align: $type;
  align-items: $type;
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-ms-input-placeholder {
    @content;
  }
  &::placeholder {
    @content;
  }
}
