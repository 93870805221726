@import 'src/styles/font-stack';
@import './mixins.scss';

.calculator {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  --color-text-calculator: var(--color-dark-blue-500);

  &.inverted {
    --color-text-calculator: var(--color-text-inverted);
    color: var(--color-text-calculator);
  }

  .label,
  .cost {
    text-align: center;
  }

  .label {
    @include font-input-label-14;
  }

  .cost {
    @include font-display-50;
  }
}

.monthlyCost {
  display: grid;
  height: auto;
  width: 100%;
  align-self: center;
  margin-bottom: 0rem;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 1rem;
  background: white;
  color: #000033;
  text-align: center;
  h2 {
    margin-bottom: 0;
    font-size: 2rem;
  }
  p {
    font-weight: bold;

    letter-spacing: -1px;
  }

  span {
    @include fontSize('md');

    color: #000033;
  }
}

.validCouponText p {
  font-size: 0.9rem;
  color: #fff;
  margin: 0;
  padding: 0;
}
.interestRate {
  font-size: 1rem;
}
