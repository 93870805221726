@import 'src/styles/font-stack';
@import 'src/styles/not-supports-gap';

.ListItem {
  list-style: none;
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  width: 100%;

  > div {
    display: flex;
    align-items: center;
  }
}

.normal {
  padding: 0.5rem;

  div {
    gap: 0.875rem;

    @include not-supports-gap {
      > span + span {
        margin-left: 0.875rem;
      }
    }

    .label {
      @include font-regular-16;
    }

    .value {
      @include font-input-label-24;
      text-align: right;
    }
  }
}

.small {
  padding: 0.25rem 0.5rem;

  div {
    gap: 0.5rem;

    @include not-supports-gap {
      > span + span {
        margin-left: 0.5rem;
      }
    }

    .label {
      @include font-regular-16;
    }

    .value {
      @include font-input-label-20;
      text-align: right;
    }
  }
}

.large {
  padding: 0.8rem 0rem;

  div {
    gap: 1rem;

    @include not-supports-gap {
      > span + span {
        margin-left: 1rem;
      }
    }

    .label {
      @include font-regular-18;
    }

    .value {
      @include font-input-label-24;
      text-align: right;
    }
  }
}

.xlarge {
  div {
    gap: 1.5rem;

    @include not-supports-gap {
      > span + span {
        margin-left: 1.5rem;
      }
    }

    .label {
      @include font-title-20;
      padding: 1.25rem 0.5rem;
    }

    .value {
      @include font-input-label-24;
    }
  }
}
