@import 'src/styles/media';
@import 'src/styles/font-stack';

.switch {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  input {
    height: 0;
    width: 0;
    margin: 0;
    visibility: hidden;

    &:disabled:checked + label {
      background: var(--color-mint-sweet-100);
    }
    &:disabled + label {
      background: var(--color-dark-blue-100);
    }
    &:checked + label {
      background: var(--color-mint-sweet-500);
    }
    &:checked + label:after {
      left: calc(100% - 0.125rem);
      transform: translateX(-100%);
    }
  }

  label {
    cursor: pointer;
    text-indent: -2000rem;
    width: 3.5rem;
    height: 2rem;
    background: var(--color-dark-blue);
    display: block;
    border-radius: 100rem;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 0.125rem;
      left: 0.125rem;
      width: 1.75rem;
      height: 1.75rem;
      background: var(--color-white);
      border-radius: 100rem;
      transition: 0.3s;
    }

    &:active:after {
      width: 2.125rem;
    }
  }
}
